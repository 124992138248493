.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 900;
  text-align: center;

  &--small {
    font-size: 18px;
  }

  @include desktop-up {
    font-size: 36px;

    &--small {
      font-size: 30px;
    }
  }
}
