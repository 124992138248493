.contacts {
  position: relative;
  padding: 24px 0 112px;
  background-color: $red;
  text-align: center;

  @include tablet-up {
    padding: 41px 0 58px;
  }

  @include desktop-up {
    padding: 73px 0 200px;
  }
}

.contacts__container {
  position: relative;

  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 135px;
    height: 93px;
    right: 13px;
    bottom: -106px;
    background-image: url("../img/decor-contacts.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include tablet-up {
      width: 146px;
      height: 101px;
      right: 17.31%;
      bottom: -26px;
    }

    @include desktop-up {
      width: 242px;
      height: 168px;
      right: 193px;
      bottom: -49px;
    }
  }
}

.contacts__description {
  max-width: 232px;
  margin: 0 auto 10px;
  padding-left: 3px;
  font-size: 15px;
  font-weight: 900;
  color: $white;

  @include tablet-up {
    max-width: 385px;
    margin-bottom: 20px;
    padding: 0;
    font-size: 14.5px;
  }

  @include desktop-up {
    max-width: 645px;
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 29px;
  }
}

.contacts__e-mail {
  display: inline-block;
  min-width: 208px;
  margin-left: 13px;
  padding: 8px 15px 10px;
  font-weight: 900;
  color: $white;
  text-align: center;
  border: 3px dotted $white;
  outline: none;

  &:active {
    color: $black;
    border-color: $blue;
  }

  @include tablet-up {
    min-width: 202px;
    padding: 10px 15px 11px;
    font-size: 14px;
  }

  @include desktop-up {
    min-width: 336px;
    margin-left: 26px;
    padding: 16px 20px 18px;
    font-size: 24px;
    transition: all 0.3s;

    &:hover,
    &:focus {
      color: $black;
      border-color: $black;
    }

    &:active {
      opacity: 0.9;
    }
  }
}
