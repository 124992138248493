.button {
  @include button-reset;
  display: inline-block;
  font-family: $gotham-pro;
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-align: center;
  background-color: $blue;
  box-shadow: 4px 4px rgba($black, 0.24);

  &:active {
    box-shadow: 0 0 rgba($black, 0);
  }

  @include tablet-up {
    font-size: 15px;
  }

  @include desktop-up {
    font-size: 24px;
    transition: color 0.3s, box-shadow 0.5s, background-color 0.3s;

    &:hover,
    &:focus {
      color: $blue;
      box-shadow: inset 0 0 0 1px $blue;
      background-color: $white;
    }
  }
}
