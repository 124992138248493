.link {
  &:active {
    color: $blue;
  }

  @include desktop-up {
    position: relative;
    font: inherit;
    color: $white;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 1px;
      background-color: white;
      transition: 0.3s width;
    }

    &:hover,
    &:focus {
      &::after {
        width: 100%;
      }
    }

    &:active {
      color: $white;
    }
  }
}
