.activities {
  padding: 37px 0 32px;

  @include tablet-up {
    padding: 29px 0 8px;
  }

  @include desktop-up {
    padding: 49px 0 55px;
  }
}

.activities__list {
  @include list-reset();
  max-width: 480px;
  margin: 0 auto;

  @include tablet-up {
    display: flex;
    flex-wrap: wrap;
    max-width: 708px;
  }

  @include desktop-up {
    max-width: none;
  }
}

.activities__item {
  position: relative;
  display: block;
  margin-bottom: 50px;

  &::before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--employment {
    padding-top: 109px;

    &::before {
      width: 116px;
      height: 95px;
      left: calc(50% - 6px);
      background-image: url("../img/decor-employment.svg");
    }
  }

  &--bonuses {
    padding-top: 123px;

    &::before {
      width: 120px;
      height: 104px;
      background-image: url("../img/decor-bonuses.svg");
    }

    & h3 {
      margin-bottom: 19px;
    }
  }

  @include tablet-up {
    width: 50%;
    margin-bottom: 30px;
    padding: 98px 30px 0 35px;

    &:last-child {
      margin-bottom: 30px;
    }

    &::before {
      left: calc(50% - 8px);
    }

    &--employment {
      & h3 {
        padding-right: 15px;
      }

      &::before {
        width: 106px;
        height: 87px;
      }
    }

    &--bonuses {
      padding-left: 38px;
      padding-right: 20px;

      &::before {
        width: 104px;
        height: 89px;
      }
    }
  }

  @include desktop-up {
    padding: 167px 40px 0 52px;

    &--employment {
      & h3 {
        padding-right: 30px;
      }

      &::before {
        width: 177px;
        height: 145px;
        left: calc(50% - 20px);
      }
    }

    &--bonuses {
      padding-left: 63px;

      & h3 {
        margin-bottom: 38px;
      }

      &::before {
        width: 172px;
        height: 148px;
      }
    }
  }
}

.activities__item-title {
  margin-bottom: 24px;

  @include tablet-up {
    margin-bottom: 20px;
  }

  @include desktop-up {
    margin-bottom: 38px;
  }
}

.activities__item-description {
  margin: 0;
  font-size: 15px;
  text-align: justify;
  hyphens: auto;

  @include tablet-up {
    font-size: 14px;
    text-align: left;
    line-height: 17.5px;
    hyphens: none;
  }

  @include desktop-up {
    font-size: 18px;
    line-height: 21px;
  }
}
