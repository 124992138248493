.joining {
  padding: 23px 28px 30px;
  background-color: $bg-color;

  @include tablet-up {
    padding: 34px 30px 50px;
    background-color: #fafafa;
  }

  @include desktop-up {
    padding: 75px 30px 68px;
  }
}

.joining__title {
  position: relative;
  margin: 0 0 18px;
  padding-top: 74px;
  font-size: 25px;
  font-weight: 900;
  text-align: center;

  b {
    color: $red;
  }

  span {
    font-weight: 300;
  }

  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 80px;
    height: 60px;
    top: 0;
    left: calc(50% + 7px);
    transform: translateX(-50%);
    background-image: url("../img/logo-part-help-team.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  @include tablet-up {
    position: relative;
    margin-bottom: 52px;
    padding-top: 75px;

    &::before {
      width: 83px;
      height: 62px;
      left: 50%;
    }
  }

  @include desktop-up {
    margin-bottom: 64px;
    padding-top: 169px;
    font-size: 36px;

    b {
      color: $black;
    }

    span {
      font-weight: 900;
    }

    &::before {
      width: 161px;
      height: 136px;
      left: calc(50% - 22px);
      background-image: url("../img/decor-help.svg");
    }
  }
}

.joining__procedure {
  @include list-reset();
  max-width: 480px;
  margin: 0 auto;

  @include tablet-up {
    display: flex;
    flex-wrap: wrap;
    max-width: 708px;
  }

  @include desktop-up {
    max-width: 1160px;
  }
}

.joining__action {
  position: relative;
  margin-bottom: 6px;
  box-sizing: content-box;

  &::before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    position: absolute;
    display: block;
    content: "";
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &--registration {
    min-height: 144px;
    padding-left: 124px;

    &::before {
      width: 131px;
      height: 146px;
      top: -2px;
      left: -10px;
      background-image: url("../img/decor-registration.svg");
    }

    & p {
      padding-left: 2px;
    }
  }

  &--acquaintance {
    padding-right: 135px;
    min-height: 153px;

    &::before {
      width: 122px;
      height: 127px;
      top: 2px;
      right: -7px;
      background-image: url("../img/decor-acquaintance.svg");
    }
  }

  &--training {
    margin-bottom: 34px;

    & h3 {
      width: 45.45%;
      margin: 0 0 20px auto;
      padding-top: 37px;
    }

    &::before {
      width: 161px;
      height: 106px;
      left: -13px;
      background-image: url("../img/decor-training.svg");
    }
  }

  &--invitation {
    min-height: 180px;
    padding-right: 100px;

    & h3 {
      padding-right: 40px;
      padding-bottom: 11px;
    }

    &::before {
      width: 116px;
      height: 195px;
      top: -12px;
      right: -5px;
      background-image: url("../img/decor-invitation.svg");
    }
  }

  @include tablet-up {
    width: 50%;
    padding: 0;
    box-sizing: border-box;

    &--registration {
      padding-left: 25.2%;

      &::before {
        width: 182px;
        height: 211px;
        top: -9px;
        left: -1px;
      }

      &::after {
        width: 111px;
        height: 51px;
        top: 19px;
        right: -120px;
        background-image: url("../img/pointer-registration.svg");
      }
    }

    &--acquaintance {
      min-height: 287px;
      padding-top: 81px;
      padding-left: 29.23%;
      text-align: right;

      & h3 {
        text-align: right;

        &::after {
          left: auto;
          right: 0;
        }
      }

      &::before {
        width: 192px;
        height: 199px;
        top: 88px;
        left: 18px;
        right: auto;
      }

      &::after {
        width: 111px;
        height: 61px;
        bottom: -51px;
        left: -118px;
        background-image: url("../img/pointer-acquaintance.svg");
      }
    }

    &--training {
      padding-right: 15.2%;

      & h3 {
        width: auto;
        max-width: 220px;
        margin: 0 0 14px;
        padding-top: 0;
      }

      &::before {
        width: 250px;
        height: 165px;
        top: 115px;
        left: auto;
        right: 15px;
      }

      &::after {
        width: 134px;
        height: 38px;
        top: 67px;
        right: -144px;
        background-image: url("../img/pointer-training.svg");
      }
    }

    &--invitation {
      min-height: 367px;
      padding-top: 90px;
      padding-left: 22%;
      text-align: right;

      & h3 {
        padding-right: 0;
        text-align: right;

        &::after {
          left: auto;
          right: 0;
        }
      }

      &::before {
        width: 138px;
        height: 232px;
        right: auto;
        top: 135px;
        left: 21px;
      }
    }
  }

  @include desktop-up {
    &--registration {
      min-height: 337px;
      padding-left: 25%;

      &::before {
        width: 303px;
        height: 351px;
        top: -14px;
        left: -8px;
      }

      &::after {
        width: 183px;
        height: 84px;
        top: 32px;
        right: -198px;
      }
    }

    &--acquaintance {
      min-height: 480px;
      padding-top: 135px;
      padding-left: 30.17%;

      &::before {
        width: 318px;
        height: 331px;
        top: 170px;
        left: 32px;
      }

      &::after {
        width: 184px;
        height: 102px;
        bottom: -82px;
        left: -194px;
      }
    }

    &--training {
      min-height: 435px;
      padding-right: 15.8%;

      & h3 {
        max-width: none;
        margin-bottom: 24px;
        padding-bottom: 13px;
      }

      &::before {
        width: 415px;
        height: 274px;
        top: 164px;
        right: 25px;
      }

      &::after {
        width: 219px;
        height: 62px;
        top: 113px;
        right: -239px;
      }
    }

    &--invitation {
      min-height: 628px;
      padding-top: 149px;
      padding-left: 21%;

      & h3 {
        margin-bottom: 24px;
        padding-bottom: 13px;
      }

      &::before {
        width: 229px;
        height: 386px;
        top: 244px;
        left: 85px;
      }
    }
  }
}

.joining__action-title {
  position: relative;
  margin-bottom: 5px;
  padding-bottom: 6px;
  font-size: 18px;
  text-align: left;
  letter-spacing: -0.05em;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 118px;
    height: 3px;
    background-color: $blue;
  }

  @include tablet-up {
    margin-bottom: 9px;
    padding-bottom: 8px;

    &::after {
      width: 138px;
    }
  }

  @include desktop-up {
    margin-bottom: 17px;
    padding-bottom: 11px;
    font-size: 30px;

    &::after {
      width: 230px;
    }
  }
}

.joining__registration-link {
  min-width: 139px;
  margin-bottom: 10px;
  padding: 8px 0 7px;
  font-size: 14px;
  font-weight: 900;
  box-shadow: 3px 3px rgba($black, 0.24);

  @include tablet-up {
    min-width: 147px;
    padding: 8px 10px;
    box-shadow: 2px 3px rgba($black, 0.24);
  }

  @include desktop-up {
    min-width: 245px;
    margin-bottom: 20px;
    padding: 13px 20px;
    font-size: 24px;
    letter-spacing: -0.05em;
    box-shadow: 4px 6px rgba($black, 0.24);
  }
}

.joining__action-description {
  margin: 0;
  letter-spacing: -0.05em;
  hyphens: auto;

  @include tablet-up {
    font-size: 14px;
    line-height: 17.5px;
  }

  @include desktop-up {
    font-size: 16px;
    line-height: 19px;
  }
}
