.social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.social__item {
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  @include tablet-up {
    margin-right: 18px;
  }

  @include desktop-up {
    margin-right: 11px;
  }
}

.social__link {
  position: relative;
  display: block;
  outline: none;

  svg {
    position: absolute;
    fill: $white;
  }

  &--fb {
    width: 25px;
    height: 25px;
    fill: $white;
  }

  &--vk {
    width: 35px;
    height: 35px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:active {
    svg {
      fill: $black;
      opacity: 0.9;
    }
  }

  @include tablet-up {
    &--fb {
      width: 13px;
      height: 13px;
    }

    &--vk {
      width: 18px;
      height: 18px;

      svg {
        top: auto;
      }
    }
  }

  @include desktop-up {
    & svg {
      transition: 0.3s all;
    }

    &--fb {
      width: 22px;
      height: 22px;
    }

    &--vk {
      width: 31px;
      height: 31px;
    }

    &:hover,
    &:focus {
      svg {
        fill: $black;
      }
    }
  }
}
