@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 400;
  src:
    url("../fonts/gotham-pro-regular.woff2") format("woff2"),
    url("../fonts/gotham-pro-regular.woff") format("woff"),
    url("../fonts/gotham-pro-regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 300;
  src:
    url("../fonts/gotham-pro-light.woff2") format("woff2"),
    url("../fonts/gotham-pro-light.woff") format("woff"),
    url("../fonts/gotham-pro-light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 700;
  src:
    url("../fonts/gotham-pro-bold.woff2") format("woff2"),
    url("../fonts/gotham-pro-bold.woff") format("woff"),
    url("../fonts/gotham-pro-bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 900;
  src:
    url("../fonts/gotham-pro-black.woff2") format("woff2"),
    url("../fonts/gotham-pro-black.woff") format("woff"),
    url("../fonts/gotham-pro-black.ttf") format("truetype");
  font-display: swap;
}
