// Media

@mixin tablet-up {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: $desktop) {
    @content;
  }
}

/* Сброс стилей */

@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin button-reset {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}
