.header {
  background-color: $red;
}

.header__top {
  position: relative;
  min-height: 46px;
  background-color: $red;
  z-index: 10;

  @include tablet-up {
    position: fixed;
    width: 100%;
    max-width: 1920px;
    min-height: auto;
  }
}

.header__links-wrapper {
  position: fixed;
  z-index: 10;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 50px 20px 58px 28px;
  transition: 0.3s height;
  background-color: $red;
  overflow: auto;

  @include tablet-up {
    position: static;
    flex-direction: row;
    height: auto;
    margin: 0 auto;
    padding: 0 30px;
  }

  @include desktop-up {
    max-width: 1220px;
  }
}

.header__nav-opening-btn {
  @include button-reset;
  position: fixed;
  top: 9px;
  right: 10px;
  display: none;
  width: 30px;
  height: 30px;
  background-color: rgba($red, 0.5);
  background-image: url("../img/burger.svg");
  background-repeat: no-repeat;
  background-position: left 7px center;
  border-radius: 50%;

  &.js-active {
    display: inline-block;

    & ~ .header__links-wrapper {
      height: 0;
      padding: 0;

      .header__nav-item {
        display: none;
      }

      .header__social-item {
        display: none;
      }
    }
  }

  @include tablet-up {
    &.js-active {
      display: none;

      & ~ .header__links-wrapper {
        height: auto;
        padding: 0 30px;

        .header__nav-item {
          display: block;
        }

        .header__social-item {
          display: inline-block;
        }
      }
    }
  }
}

.header__nav-closing-btn {
  @include button-reset;
  position: absolute;
  z-index: 11;
  top: 7px;
  left: 10px;
  display: none;
  width: 30px;
  height: 30px;
  background-image: url("../img/cross.svg");
  background-repeat: no-repeat;
  background-position: center;

  &.js-active {
    display: inline-block;
  }

  @include tablet-up {
    &.js-active {
      display: none;
    }
  }
}

.header__nav-list {
  @include list-reset;

  @include tablet-up {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  @include desktop-up {
    padding: 23px 0;
  }
}

.header__nav-item {
  @include tablet-up {
    margin-right: 17px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include desktop-up {
    margin-right: 21px;
  }
}

.header__nav-link {
  display: block;
  padding: 12px 0 13px;
  font-size: 18px;
  font-weight: 700;
  color: $white;
  outline: none;

  @include tablet-up {
    padding: 0;
    font-size: 0.967em;
    letter-spacing: -0.05em;
  }

  @include desktop-up {
    font-size: 17px;
  }
}

.header__social {
  @include list-reset;

  @include tablet-up {
    margin-top: 5px;
  }
}

.header__social-item {
  @include tablet-up {
    &:last-child {
      margin-right: 0;
    }
  }
}

.header__bottom {
  padding-top: 4px;
  padding-bottom: 22px;
  background-color: $red;

  @include tablet-up {
    padding: 55px 0 12px;
  }

  @include desktop-up {
    padding: 91px 0 22px;
  }
}

.header__container-bottom {
  @include tablet-up {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.header__info {
  max-width: 520px;
  margin-top: 0;
  margin-bottom: 13px;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 900;
  letter-spacing: -0.4px;
  color: white;

  @include tablet-up {
    max-width: 470px;
    margin: 0;
    font-size: 1.449em;
    line-height: 24px;
  }

  @include desktop-up {
    max-width: 780px;
    margin: 0;
    font-size: 36px;
    line-height: 40px;
  }
}

.header__logo {
  @include tablet-up {
    width: 173px;
    height: 39px;
    margin-top: 5px;
  }

  @include desktop-up {
    width: 287px;
    height: 64px;
    margin-top: 9px;
  }
}
