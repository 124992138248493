.conference {
  padding: 26px 0 62px;
  text-align: center;
  background-color: $red;

  @include tablet-up {
    padding: 20px 0 27px;
  }

  @include desktop-up {
    padding: 37px 0 47px;
  }
}

.conference__title {
  margin-bottom: 12px;
  color: $white;

  @include tablet-up {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  @include desktop-up {
    margin-bottom: 35px;
    padding-left: 33px;
  }
}

.conference__list {
  @include list-reset();
  max-width: 480px;
  margin: 0 auto;

  @include tablet-up {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 708px;
    padding: 0 5px;
  }

  @include desktop-up {
    max-width: none;
    margin: 0;
    padding: 0;
  }
}

.conference__item {
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 44px;
  }

  @include tablet-up {
    width: 337px;
    margin-bottom: 29px;

    &:last-child {
      margin-bottom: 29px;
    }
  }

  @include desktop-up {
    width: 48.28%;
    margin-bottom: 52px;

    &:last-child {
      margin-bottom: 52px;
    }
  }
}

.conference__card {
  display: flex;
  flex-direction: column;
  padding: 12px 13px 16px 15px;
  text-align: left;
  background-color: white;
  box-shadow: 1px 2px rgba($black, 0.35);

  @include tablet-up {
    height: 100%;
    padding: 17px 25px 20px 28px;

    &--iot {
      & .conference__card-link {
        display: none;
      }
    }
  }

  @include desktop-up {
    padding: 28px 42px 49px 50px;
    box-shadow: 4px 6px rgba($black, 0.35);
  }
}

.conference__registration-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 58px;
  margin-bottom: 16px;

  @include tablet-up {
    min-height: 56px;
    margin-bottom: 12px;
  }

  @include desktop-up {
    margin-bottom: 2px;
    min-height: 110px;
  }
}

.conference__logo {
  margin: 0;
  font-size: 0;
  line-height: 0;

  &--tl {
    margin-top: 12px;
  }

  @include tablet-up {

    max-width: 180px;
    height: 50px;
    
    & img {
      width: auto;
      max-height: 100%;
    }

    &--tl {
      margin-top: 9px;
      width: 142px;
      height: auto;
    }

    &--iot {
      width: 100px;
      height: auto;
      margin-left: 6px;
    }

    &--rit {
      width: 117px;
    }
  }

  @include desktop-up {

    max-width: 260px;
    height: 90px;
    
    &--tl {
      width: 236px;
      height: auto;
      margin-top: 27px;
    }

    &--iot {
      width: 167px;
      height: auto;
    }

    &--rit {
      width: 230px;
    }
  }
}

.conference__card-link {
  min-width: 85px;
  margin-top: 12px;
  padding: 4px;
  font-size: 10px;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);

  @include tablet-up {
    min-width: 96px;
    margin-top: 9px;
    padding: 5.5px 0;
    font-size: 10px;
  }

  @include desktop-up {
    min-width: 159px;
    margin-top: 16px;
    padding: 9px 5px 10px;
    font-size: 16px;
    box-shadow: 3px 4px rgba($black, 0.2);
  }
}

.conference__location {
  position: relative;
  margin: 0 0 10px;
  padding-left: 18px;
  font-size: 12px;
  font-weight: 700;

  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 10px;
    height: 14px;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    background-image: url("../img/ico-location.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include tablet-up {
    margin-bottom: 9px;
    font-size: 14px;
  }

  @include desktop-up {
    margin-bottom: 17px;
    padding-left: 25px;
    font-size: 18px;

    &::before {
      width: 15px;
      height: 20px;
    }
  }
}

.conference__card-description {
  margin: 0;
  padding-right: 20px;
  padding-left: 2px;
  font-size: 12px;
  font-weight: 300;

  @include tablet-up {
    max-width: 225px;
    padding-right: 0;
  }

  @include desktop-up {
    max-width: 380px;
    font-size: 18px;
  }
}

.conference__link {
  min-width: 264px;
  padding: 11px 10px 14px;
  font-size: 18px;
  box-shadow: 3px 3px rgba($black, 0.24);

  @include tablet-up {
    min-width: 191px;
    padding: 9px 10px;
    font-size: 12px;
  }

  @include desktop-up {
    min-width: 272px;
    margin-right: 10px;
    padding: 14px 20px 13px;
    font-size: 16px;
    box-shadow: 4px 6px rgba($black, 0.35);
  }
}
