html {
  scroll-behavior: smooth;
}

html,
body {
  min-width: 320px;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $gotham-pro;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  line-height: 1.2;
  color: black;
  background-color: white;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

textarea {
  resize: vertical;
}

img {
  max-width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  padding-left: 28px;
  padding-right: 28px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: $desktop;

  @include tablet-up {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.container-xl {
  width: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.js-no-scroll {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;

  @include tablet-up {
    height: auto;
    width: auto;
    position: static;
  }
}
