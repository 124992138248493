.intro {
  position: relative;
  min-height: 518px;
  overflow: hidden;

  @include tablet-up {
    min-height: 364px;
  }

  @include desktop-up {
    min-height: 600px;
  }
}

.intro__slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro__slide {
  display: block;
  background-position: center;
  background-size: cover;

  & span {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
    line-height: 0;
  }
}

.intro__text-wrapper {
  position: relative;
  display: block;
  width: 100%;
  min-height: inherit;
  height: 100%;
  padding: 39px 28px 101px;
  background-color: rgba(#181b3e, 0.84);
  z-index: 2;

  @include tablet-up {
    padding: 90px 30px 86px;
  }

  @include desktop-up {
    padding: 147px 0 160px;
  }
}

.intro__title {
  position: relative;
  margin-bottom: 35px;
  padding-top: 124px;
  padding-left: 16px;
  color: white;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 168px;
    height: 103px;
    background: url("../img/decor-intro.svg") 0 0 no-repeat;
    background-size: 149px 103px;
  }

  @include tablet-up {
    max-width: 330px;
    margin: 0 auto 20px;
    padding: 32px 0 20px 150px;
    font-size: 30px;
    text-align: left;

    &::before {
      width: 123px;
      height: 85px;
      left: 0;
      transform: none;
      background-size: contain;
    }
  }

  @include desktop-up {
    max-width: 546px;
    margin-bottom: 34px;
    padding: 56px 0 34px 247px;
    font-size: 48px;

    &::before {
      width: 204px;
      height: 141px;
    }
  }
}

.intro__description {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  color: $white;
  z-index: 3;

  @include tablet-up {
    max-width: 525px;
    margin: 0 auto;
    font-size: 17px;
  }

  @include desktop-up {
    max-width: 875px;
    font-size: 24px;
  }
}
