.work {
  padding: 27px 0 44px;
  text-align: center;

  @include tablet-up {
    padding: 19px 0 13px;
  }

  @include desktop-up {
    padding: 35px 0 38px;
  }
}

.work__description {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 300;

  @include tablet-up {
    max-width: 615px;
    margin: 0 auto 14px;
    font-size: 14px;
  }

  @include desktop-up {
    max-width: 790px;
    margin-bottom: 24px;
    font-size: 18px;
  }
}

.work__slogan {
  margin-top: 0;
  margin-bottom: 22px;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 900;

  @include tablet-up {
    margin-bottom: 10px;
    font-size: 14.5px;
  }

  @include desktop-up {
    margin-bottom: 21px;
    font-size: 24px;
  }
}

.work__link {
  min-width: 264px;
  padding-top: 10px;
  padding-bottom: 13px;
  box-shadow: 3px 3px rgba($black, 0.24);

  @include tablet-up {
    min-width: 213px;
    padding: 7px 15px 11px;
    letter-spacing: -0.05em;
    box-shadow: 2px 3px rgba($black, 0.24);
  }

  @include desktop-up {
    min-width: 354px;
    padding: 16px 20px;
    box-shadow: 4px 6px rgba($black, 0.24);
  }
}

.work--prompt {
  padding: 29px 0 36px;

  .work__slogan {
    position: relative;
    margin-bottom: 20px;
    line-height: 22px;

    span {
      display: block;
    }

    span:last-child {
      color: $gray;
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 29px;
      height: 68px;
      top: 11px;
      right: -8px;
      background-image: url("../img/pointer-work-mob.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .work__link {
    padding: 10px 10px 15px;
  }

  @include tablet-up {
    padding: 35px 0 43px;

    & .work__slogan {
      max-width: 440px;
      margin: 0 auto 28px;
      padding: 0;
      padding-right: 20px;
      font-size: 20px;

      &::after {
        width: 40px;
        height: 55px;
        top: 32px;
        right: 69px;
        background-image: url("../img/pointer-work.svg");
      }
    }

    .work__link {
      min-width: 203px;
      margin-right: 5px;
      padding: 9px 10px 10px;
      letter-spacing: 0;
    }
  }

  @include desktop-up {
    padding: 69px 0 60px;

    & .work__slogan {
      max-width: 740px;
      margin-bottom: 39px;
      padding: 0;
      font-size: 36px;
      line-height: 42px;

      &::after {
        width: 67px;
        height: 91px;
        top: 81px;
        right: 122px;
      }
    }

    .work__link {
      min-width: 338px;
      padding: 16px 20px 18px;
    }
  }
}
